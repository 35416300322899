/* ===== Header ===== */
/* ===== Sidebar ===== */


.overlay {
  content: "";
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(40, 40, 40, .4);
  z-index: -1;
}

/* ===== Header ===== */
.header {
  height: var(--headerheight);
  display: flex;
  border-radius: var(--headerbr);
  background-color: var(--primaryblue);
  margin-bottom: var(--splayout);
}

.borderLeftHd {
  border-radius: inherit;
}

.leftHdBox {
  border-radius: 12px;
  z-index: 101;
  background-color: var(--primaryblue);
  position: relative;
  padding-left: 24px;
}

.leftHdBox:before {
  content: '';
  position: absolute;
  width: 12px;
  height: 100%;
  left: 0px;
  background-color: var(--secondarygreen);
  top: 0;
  border-radius: var(--headerbr) 0 0 var(--headerbr);
}

.leftHdBox img {
  width: 40px;
  height: auto;
  border-radius: 50%;
}

.midHdBox {
  background-color: var(--greybkg);
  border-bottom-right-radius: var(--headerbr);
  border-top-left-radius: var(--headerbr);
}

.rgtHdBox {
  display: flex;
  align-items: center;
  justify-content: center;
}

.leftPTag {
  margin-top: 0;
  margin-bottom: 2px;
  font-size: small;
  text-shadow: 0 0 white;
}

.leftHdDiv {
  display: flex;
  align-items: center !important;
  flex-direction: column;
  justify-content: center;
}

.selectOption {
  font-size: small;
  background-color: 'var(--grey)';
}

.locationSelector {
  align-self: center;
}

.locSelect {
  width: auto;
  text-align: center;
}

.outlinedInput {
  min-width: 65px;
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}

/* ===== Sidebar ===== */
.signOut {
  font-size: 40px !important;
}

.signOutBtn {
  border: none;
  background: none;
  color: inherit;
  cursor: pointer;
  outline: none;
  position: absolute;
  top: 40px;
  right: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #ccc;
}

.signOutBtn:hover {
  color: #58b261;
}

.menuList :global .MuiListItem-root:hover {
  border-right: 4px solid green;
}

.menuList :global .MuiListItem-root:hover .MuiButtonBase-root {
  color: #58b261;
}

.menuIcon img {
  width: 100%;
}

.menuTitle {
  color: white;
  background-color: #51a65f3d;
  text-align: center;
  margin: 14px 0;
  padding: 7px;
  border-bottom: 1px solid #51a65f;
  font-size: 14px;
  font-weight: 600;
}

@media screen and (max-width: 900px) {
  .rgtHdBox {
    background-color: var(--primaryblue);
    border-radius: 15px;
    justify-content: space-around;
    padding: 12px;
    position: absolute !important;
    top: calc(var(--headerheight) + 48px);
    min-width: calc(100% - var(--splayout)*2);
  }

  .midHdBox {
    border-top-right-radius: var(--headerbr);
  }
}

.showPrint {
  display: none;
}

.hidePrint {
  display: block;
}

@media print {

  .showPrint {
    display: block;
  }

  .hidePrint {
    display: none;
  }

  .printSideLogo {
    width: 50px;
    height: 50px;
    background-color: var(--primaryblue);
    margin-right: 10px;
    padding: 6px;
  }

  .borderLeftHd {
    border-left: none
  }

  .header,
  .leftHdBox {
    margin-bottom: 0;
    border-bottom: 1px solid grey;
    border-radius: 0;
  }

}