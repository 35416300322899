.documentCol {
  font-size: 13px;
  flex-direction: column;
}

.docWrapper {
  width: 100%;
  padding-left: 12px;
  min-height: 30px;
}

.iconButtonWrapper {
  margin-left: auto;
}

.textCenter {
  text-align: center;
  height: 200px;
}

.iacName {
  font-size: 11px;
  line-height: normal;
  display: inline-block;
}

.docName {
  padding: 2px;
  padding-left: 0px;
  padding-right: 12px;
  color: var(--secondarygreen);
  font-weight: bold;
  min-width: 64px;
  text-transform: uppercase;
  white-space: nowrap;
  margin-top: auto;
}

.docLabel {
  border-bottom: 1px solid #c8c8c8eb;
  padding: 6px 0;
  margin: 0 6px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  border-radius: 0px;
}

.textDanger {
  color: #E61A1A;
}

.prescreen {
  width: calc(100% - 100px);
}

.txtRed {
  color: red;
}

.docLabel svg {
  color: #fff;
}


.docLabel:last-child {
  margin-bottom: 0px;
}

.docLabel:last-child {
  margin-bottom: 0px;
}

.docVal {
  flex: auto;
  display: flex;
  align-items: center;
  color: #fff;
  min-height: 30px;
  line-height: 1.2;
}


/* new */
.screeningInfoIcon {
  margin-left: auto !important;
}

.screeningInfoIcon img:not(.screeningInfoIcon .remarksActionIcon img),
img.screeningInfoIcon {
  height: 1rem;
  width: auto;
}

.tabIcon {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0;
}

.remarksIcon {
  background-color: var(--grey);
  min-width: 18px;
  height: 18px;
  border-radius: 20px;
  color: var(--primaryblue);
  font-weight: bolder;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: normal;
  font-size: 0.6rem;
  text-shadow: 0 0 black;
  cursor: pointer;
}

.remarksIconGreen {
  background-color: var(--secondarygreen);
  min-width: 18px;
  height: 18px;
  border-radius: 20px;
  color: var(--primaryblue);
  font-weight: bolder;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: normal;
  font-size: 0.6rem;
  text-shadow: 0 0 black;
  cursor: pointer;
}

.remarksInput {
  margin-left: -20px;
}
.remarksInput :global .MuiInputBase-root {
  padding: 0px 10px 0;
}

.screeningInfoIcon .remarksActionIcon img {
  height: 100%;
}

.select {
  color: red;
  background-color: 'yellow';
}

/* Consignment Security Declaration */
.csdGridWrapper {
  --csdBorder: 1px;
  border: var(--csdBorder) solid #969696;
  border-width: 0 0 1px 1px;
  border-radius: 4px;
  background-color: var(--greybkg);
}

.csdGridWrapper>.csdGridItem,
.csdGridWrapper .csdGridItem {
  border: var(--csdBorder) solid #969696;
  border-width: 1px 1px 0 0;
  /* padding: 12px; */
  padding: 1vw;
}

.fs34 {
  font-size: 34px !important;
}

.fs11 {
  font-size: 11px;
}

.logP p {
  margin: 0;
}

.logP {
  min-height: 100px;
  margin-top: 10px;
}

.identifierWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

/* xx */
.grey {
  border-color: grey;
}

.red {
  border: 1px solid red;
}

.showPrint {
  display: none;
}

.searchIconAwb img {
  width: 20px;
}

.ccsfPopover {
  min-width: 380px;
}

.ccsfPopoverContent {
  min-height: 254px
}

@media print {
  @page {
    size: portrait
  }

  .hidePrint {
    display: none;
  }

  .showPrint {
    display: block;
  }
}