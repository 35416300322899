.airlineTableOuter {
  height: calc(var(--contentBox) - 88px);
} 

.checkboxWrapper {
  transform: scale(0.8);
}

.tableTextField input {
  width: 50px;
  padding: 2px 4px;
  background-color: white;
}