.transferTableOuter {
  height: calc(var(--contentBox) - 81px);
}

.transAwbTableOuter {
  height: calc(var(--contentBox) - 370px);
}

.status {
  font-size: 20px;
  font-weight: bolder;
  line-height: 0;
}

.transferDetails {
  border-radius: 15px;
  display: flex;
}

.details {
  background-color: var(--primaryblue);
  color: white;
  border-radius: inherit;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding: 0.2rem 0.6rem;
  min-width: 50px;
  margin: auto;
  height: auto;
  font-size: 0.7rem;
  text-align: center;
}

.title {
  background-color: var(--secondarygreen);
  color: white;
  border-radius: inherit;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: 0.2rem;
  min-width: 50px;
  margin: auto;
  height: auto;
  font-size: 0.7rem;
  text-align: center;
}

.popUp {
  z-index: 10;
}

/* worksheet */

.heading {
  margin-top: 0;
  margin-bottom: 5px;
  color: var(--primaryblue);
  font-size: 13px;
}

.wtOuterGridItems {
  border-radius: 15px;
  padding: 14px;
  background-color: var(--greybkg);
}

.block {
  text-align: center;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  height: 100%;
}

.blockTitle {
  background-color: var(--primaryblue);
  color: white;
  text-shadow: 0 0 #a4a4a4;
  font-size: 0.75rem;
  border-radius: inherit;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding: 4px;
}

.blockData {
  font-size: 0.75rem;
  background-color: white;
  color: var(--primaryblue);
  padding: 10px;
  font-weight: bold;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  min-height: 38px;
}

.blockData:not(.editable) {
  background-color: lightgrey;
  align-items: center;
}

.avgContainer .wtGridItems {
  flex: calc(100% /7);
  min-width: 170px;
}

.icon img {
  filter: none;
}

@media screen and (max-width: 899px) {
  .avgContainer .wtGridItems {
    flex: calc(100% /5);
  }
}

@media screen and (max-width: 599px) {
  .avgContainer .wtGridItems {
    min-width: 100%;
  }
}