.addTextField {
  width: clamp(100px, 74%, 173px);
  padding-left: 10px;
  margin-left: auto;
}

.docBtn :global .MuiButton-root {
  min-width: 165px;
}

.docBtnOnly :global .MuiButton-root {
  margin-left: auto;
  display: block;
}

.canvasDoc {
  /* max-width: 1145px;
  display: block;
  align-content: center;
  height: auto;
  width: auto; */
  height: 100%;
}

.itsOkText {
  color: green;
  font-size: 12px;
}

.itsNotOkText {
  color: red;
  font-size: 12px;
}

.check svg {
  font-size: 18px;
  margin: auto;
  display: block;
  margin-top: 4px;
}

.canvasDoc canvas {
  margin: auto;
  width: 100% !important;
  height: auto !important;
}

.docDoc {
  overflow-x: hidden;
}

.docPage {
  min-width: auto !important;
  min-height: auto !important;
}

.docPage .annotationLayer {

  height: 70px !important;
  display: none;
}

canvas~div,
canvas+div {
  height: 70px !important;
}

.image {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.dateFilter input {
  font-size: 0.65rem !important;
}

.checkBoxxs span {
  font-size: 0.7rem !important;
}

.checkBoxxs span:nth-child(2) {
  margin-left: -6px;
  white-space: nowrap;
}

.docs {
  width: 100%;
  max-width: 500px;
  display: block;
  margin: auto;
}

.docSec {
  width: 440px;
}

.docView {
 width: calc(100% - (2 * 440px));
 scrollbar-width: thin;
}


.h100 {
  height: 100%;
}

.datePicker :global .MuiInputBase-root {
  padding-right: 12px;
}

.datePicker :global .MuiInputBase-root .MuiInputBase-input {
  font-size: 11px;
  padding-left: 10px;
  width: 66px
}

.datePicker :global .MuiInputAdornment-root {
  margin-left: -4px;
}

.delIcon {
  height: 16px;
  width: 12px;
}

.delIcon img {
  height: 100%;
}

.docRadioWrapper :global .MuiFormControlLabel-label {
  font-size: 12px;
}

.iacName {
  font-size: 11px;
  line-height: normal;
  display: inline-block;
}

.htmlRadio {
  accent-color: var(--primaryblue);
}

.checkWrapper :global .MuiFormControlLabel-label{
  font-size: 12px;
  margin-left: -3px;
  white-space: nowrap;
}
.checkWrapper{
  margin-left: -19px;
}

.incomingDocHead {
  position: sticky;
  top: calc(-1 * var(--splayout));
  background: var(--greybkg);
  z-index: 1;
  padding: var(--splayout);
  margin: calc(-1 * var(--splayout));
  margin-bottom: 0;
}
.px4 {
  padding-right: 24px;
  padding-left: 24px;
}

.listDocBody, .viewDocBody, .verifyDocBody {
  overflow: auto;
  margin-right: -24px;
  margin-left: -24px;
  scrollbar-width: thin;
}

.listDocBody {
  height: calc(100vh - 390px);
}

.viewDocBody {
  height: calc(100vh - 250px);
}

.verifyDocBody {
  height: calc(100vh - 310px);
}


@media screen and (max-width: 1440px) and (min-width: 1201px) and (max-width: 476px) {
  .docBtn :global .MuiButton-root {
    min-width: 100%;
  }

  .docBtn :global .MuiButton-root:first-child {
    margin-bottom: 12px;
  }
}

@media screen and (max-width: 1200px) {
  .docSecVerify {
    width: 100%;
  }
/* 
  .mdmt {
    margin-top: 1rem;
  } */
}

@media screen and (max-width: 768px) {
    .docSecVerify,
    .docSecList {
      width: 100%;
    }
  }

@media screen and (max-width: 476px) {
  .docBtn :global .MuiButton-root {
    min-width: 100%;
  }

  .docBtn :global .MuiButton-root:first-child {
    margin-bottom: 12px;
  }
}