.loginWrapper {
  width: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - 2*var(--splayout));
  margin: auto;
}

.loginLogo {
  width: 90%;
}

.textfield input {
  padding: 4px 12px;
  min-height: 34px;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.loginInput {
  background-color: white;
  padding: 14px 12px;
  border-radius: 10px;
  font-size: 14px;
}

.textfield :global .MuiSvgIcon-root {
  font-size: 34px;
  background-color: #0a2b4a;
  border-radius: 50%;
  color: white;
  padding: 4px;
  outline: 1px solid #58b261;
  outline-offset: 2px;
}

.appName {
  position: relative;
  padding-bottom: 6px;
}

.appName:after {
  content: '';
  position: absolute;
  background-color: #59b262;
  width: 33px;
  height: 3px;
  bottom: 0;
  right: 50%;
  transform: translateX(50%);
}

.submitBtn{
  min-height: 38px;
}

.passwordPolicyConditions {
  display: flex;
  align-items: flex-start;
}

.greyText {
  font-size: 0.8rem;
}