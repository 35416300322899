.fileName {
  overflow: hidden;
  max-width: 20ch;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
}

.drawerFileName {
  max-width: 8ch;
}

.pdfWrapper {
  background-color: white;
  border-radius: 10px;
  padding: 0.8rem;
  width: max-content;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pdfWrapper {
  width: 90px;
}

.trainingAction img {
  height: 16px;
  width: auto;
}

.attListWrapper {
  max-height: calc(100vh - 150px);
  overflow: auto;
  /* padding-right: 20px;
  margin-right: -16px;
  margin-left: -24px; */
}

.docAddIcon img{
  width: 24px;
}

.mIcon {
  margin-left: 4px;
  margin-top: -2px;
}