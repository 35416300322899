.blocks {
  display: contents !important;
}

.tabArrows {
  align-self: center;
}

.tabArrows svg {
  font-size: 3rem;
}

.fJcAround {
  justify-content: space-between;
}

.headerTab {
  margin-bottom: 24px;
}

.whiteListcon {
  max-width: 30px;
}

.menuIcon {
  margin-bottom: 0 !important;
}

@media screen and (min-width: 1201px) {
  .headerTab {
    position: absolute;
    top: 30px;
    right: 0;
    left: 0;
    width: calc(100% - 24px - 24px - 620px);
    max-width: 950px;
    /* 100% - marginRight - marginLeft - Right/Left Header blue block */
    margin: auto;
  }
}