.filterWrapperOuter {
  background-color: var(--primaryblue);
  border-radius: 10px;
  color: white;
  padding: 20px;
  height: 100%;
}

.filterTitle {
  color: var(--secondarygreen);
  margin-bottom: 2px;
  margin-top: 0;
  border-bottom: 1px solid rgba(211, 211, 211, 0.623);
  font-size: 14px;
  padding-bottom: 4px;
}

.carrierCheckbox {
  min-width: calc(100% / 3 - 5px);
}

.heading {
  margin-top: 0;
  margin-bottom: 5px;
  color: var(--primaryblue);
  font-size: 13px;
}


.block {
  text-align: center;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
}

.blockTitle {
  background-color: var(--primaryblue);
  color: white;
  font-size: 12px;
  border-radius: inherit;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding: 4px;
}

.blockData {
  font-size: 18px;
  color: var(--primaryblue);
  background-color: white;
  border-radius: inherit;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 5px 2px;
  font-weight: bold;
  line-height: normal;
}

.noOfOrders {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.customerRadioGroup {
  flex: auto;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: nowrap !important;
}

.customerRadioGroup label {
  white-space: nowrap;
  text-transform: capitalize;
}

.ip40 {
  width: 40px;
}

.customerGridItemsFilter {
  min-width: 300px;
  width: 300px;
}

.customerGridItemsData {
  width: calc(100% - 300px);
}

.filterCheckbox {
  color: white !important;
  /* background-color: white; */
}

.avgContainer .wtGridItems {
  width: calc(100% /5);
  min-width: 118px;
}

.graphData canvas {
  max-height: 400px !important;
  min-height: 400px !important;
}

.brIp {
  border-radius: 20px !important;
}

.fileImg img {
  width: 20px;
  margin-left: auto;
  display: block;
  cursor: pointer;
}

.totalWaitTime {
  font-size: 13px !important;
  font-weight: 500;
  color: var(--primaryblue)
}

/* Security Screening */
.securityscreening .wtOuterGridItems :global .MuiCard-root {
  height: 100%;
}

.graphWrapper {
  background-color: white;
  padding: 14px;
  border-radius: 10px;
  /* min-height: 200px;
  max-height: 320px;
  display: flex; */
  height: 85%;
}

.saGrapgWrap {
  width: 60%;
  margin-left: auto;
  flex: 1 1 260px;
}

.barWrapper {
  width: 100% !important;
  margin: auto;
}

.barWrapper:not(.saGrapgWrap .barWrapper) {
  margin: auto;
  width: clamp(60%, 500px, 100%);
}

.pieWrapper {
  /* width: 100%;
  margin: auto; */
  height: 100%;
}

.tsWrapper {
  padding: 0 10px;
}

.topScreener {
  background-color: var(--primaryblue);
  border-radius: 50%;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  position: relative;
  padding: 10px;
}

.tcStarWrapper {
  position: relative;
}

.tcStar {
  font-size: 60px !important;
}

.tcNo {
  padding: 15px;
  z-index: 1;
  position: relative;
  font-size: 12px;
  line-height: normal;
  font-weight: bold;
  margin-top: -1px;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}

.tsStarWrapper {
  position: relative;
  color: white;
  font-size: 0;
}

.tsStar {
  font-size: 45px !important;
}

.tsNo {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  font-size: 0.5rem;
  line-height: normal;
  font-weight: bold;
  margin-top: -1px;
}

/* slider */
.slider {
  width: calc(100% - 100px);
  margin: auto;
}

.leftArrow,
.rightArrow {
  position: absolute !important;
  top: 50%;
  transform: translateY(-50%);
}

.leftArrowIcon:hover,
.rightArrowIcon:hover {
  background-color: transparent !important;
}

.leftArrowIcon,
.rightArrowIcon {
  font-size: 60px !important;
}

.leftArrow {
  left: -60px;
}

.rightArrow {
  right: -60px;
}

.tsName {
  color: lightgrey;
  text-align: center;
  line-height: 1.2;
  font-weight: 700;
  font-size: 12px;
  padding: 0 8px;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  /* autoprefixer: off */

}

.tsDataWrapper {
  background-color: white;
  color: var(--primaryblue);
  border-radius: 10px;
  padding: 10px;
  margin-top: -20px;
  padding-top: 20px;
  min-height: 80px;
}

.tsDataWrapper>p {
  margin: 0;
}

.tsData {
  font-size: 16px;
}

.tsDataUnit {
  font-size: 10px;
}

.saBell {
  font-size: 100px !important;
  color: var(--redText);
}

.totalAlarms {
  color: white;
  font-size: 14px;
  font-weight: 700;
  position: absolute;
  top: 50%;
  right: calc(50% + 6px);
  transform: translate(50%, -50%);
}

.totalAlarmsText {
  font-size: 12px;
  font-weight: 500;
  text-align: center;
}

.alarmWrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.saBell,
.totalAlarmsText {
  margin-left: -12px;
}

.ussFormGroup {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tsSlider {
  width: calc(100% - 20px);
  margin: auto;
}

.tsleftArrow {
  left: -14px;
}

.tsrightArrow {
  right: -14px;
}

.tsleftArrow,
.tsrightArrow {
  position: absolute !important;
  top: 50%;
  transform: translateY(-50%);
  padding: 0 !important;
}

.tsleftArrowIcon,
.tsrightArrowIcon {
  width: 0.6em;
  height: 0.6em;
}

@media screen and (max-width: 1200px) {
  .orderHeading {
    width: 100%;
  }

  .ussFormGroup {
    justify-content: flex-start;
  }
}

@media screen and (max-width: 960px) {

  .customerGridItemsFilter,
  .customerGridItemsData {
    width: 100%;
  }

  .carrierCheckbox {
    min-width: calc(100% / 10 - 5px);
  }

  .wtOuterGridItems :global .MuiCard-root {
    height: 100%;
  }

  .tcRadioGroup {
    order: 3;
    width: 100%;
    margin-top: 10px;
    flex-wrap: wrap !important;
    padding: 0px;
  }
}

@media screen and (max-width: 680px) {
  .avgContainer .wtGridItems {
    min-width: calc(100% /2);
  }
}

@media screen and (max-width: 599px) {
  .avgContainer .wtGridItems {
    min-width: 100%;
  }

  .customerRadioGroup,
  .tcRadioGroup {
    justify-content: flex-start;
  }
}