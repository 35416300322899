.screenTableOuter {
  height: calc(var(--contentBox) - 90px);
}

.ipLabel {
  overflow: visible;
  text-overflow: unset;
  margin: auto 10px auto 0;
}
.loader {
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 900px) {
  .ipLabel :global .MuiInputLabel-root {
    min-width: 90px;
    white-space: normal;
  }
}