.userTableOuter {
  height: calc(var(--contentBox) - 98px);
}

.checkboxWrapper {
  transform: scale(0.8);
}

.tableTextField input {
  width: 50px;
  padding: 2px 4px;
  background-color: white;
  font-size: 12px;
  border-radius: inherit;
}

.locCheckWrapper {
  padding-top: 10px;
}

.locCheckWrapper > label {
  width: 50%;
  margin: auto;
  display: flex;
  justify-content: space-between;
}

.trainingPaper {
  width: 400px;
}

.trainingTitle {
  min-width: 120px;
  display: inline-block;
}

.trainingAction img {
  width: 14px;
  height: auto;
}

.inputForm {
  display: flex;
  flex-direction: column;
}

.addTextField {
  margin: 10px 0px;
}

.modalTitle {
  background: var(--primaryblue);
  color: white
}

.selectRole {
  padding: 5px;
}

.addTextField input  {
  padding: 8px;
  font-size: small !important;
}

.addTextField select  {
  padding: 8px;
  font-size: small !important;
}

.datePicker input{
  padding: 9px !important;
  font-size: small;
}

.datePicker button{
  padding: 7px !important;
}

.selectInput {
  color: grey ;
  font-size: small;
}

.trainingCertText {
  margin-top: 15px;
  margin-bottom: auto;
}

.addMoreButton {
  max-width: 120px;
  align-self: center;
}


/* users */

.wrapper {
  display: flex;
  flex-direction: column;
  width: 400px;
  margin-top: 5px;
  margin-bottom: 5px;
  height: 100px;
}

.name {
  color: var(--primaryblue);
  font-size: 24px;
  font-weight: 600;
}

.address {
  color: rgba(0, 0, 0, 0.54);
  font-size: 13px;
  margin-top: 0;
  margin-bottom: 5px;
}

.line {
  width: 99%;
  border: 0.5px solid #D3D3D3;
}

.ticker {
  margin: 0 auto;
  white-space: nowrap;
  overflow: hidden;
  width: 99%;
}

.ticker span {
  display: inline-block;
  padding-left: 100%;
  font-size: 0.875rem;
  color: var(--primaryblue);
  animation: ticker 8s linear infinite;
}

@keyframes ticker {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(-100%, 0);
  }
}

.dialogTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.info {
  width: 75%;
}

.iconWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around
}

.passwordPolicyConditions {
  font-size: 12px;
  padding-bottom: 8px;
  display: flex;
  align-items: flex-start;
}

.passwordPolicyHeader {
  padding-bottom: 8px;
  font-size: 14px;
  font-weight: bold;
}

.passwordPolicyContainer {
  margin-bottom: 20px;
}

.gridContainer {
  margin-bottom: 10px;
}

.titleColor {
  color: var(--primaryblue);
  font-weight: 600;
  font-size: 13px;
  min-width: 110px;
}
.gridData {
  font-size: 13px;
}

/* Print Password */
.printOuter {
  max-width: 600px;
  margin: auto;
}

@media print  {
  .hidePrint {
    display: none;
  }

}

@media screen and (max-width:480px) {
  .gridContainer {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }

}