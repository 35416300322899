@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
  font-family: "Nunito", sans-serif !important;
}

body {
  font-size: 14px;
  padding: var(--splayout);
}

/* body, * { font-family: "Nunito", sans-serif; } */

:root {
  --primaryblue: #092b4a;
  /* same as theme.palette.primary.main */
  --secondarygreen: #59b362;
  /* same as theme.palette.secondary.main */
  --grey: #c9c9c9;
  --greybkg: #f2f2f2;
  --splayout: 24px;
  /* same as theme.spacing(6) */
  --tbbr: 8px;
  /* table border radius */
  --disabledfilter: grayscale(1);
  --disabledop: 0.4;

  --xs: 0;
  --sm: 600;
  --md: 900;
  --lg: 1200;
  --xl: 1536;


  --input-fs: 12px;
  --m-wrapper: 30px;
  --p-grid: 20px;
  --redText: #D02139;
  --disabled: #cdcdcd;
  /* input */
  --label: #333333;
  --inputheight: 28px;
  --ip-br: 4px;
  /* new design */
  --tbl_head_bkg: var(--primaryblue);
  --tbl_head_color: rgb(255, 255, 255);
  --greybkg: #e8e8e8;
  --tbl_border: #c9c9c9;
  --gap: 25px;
  --overlay: #00000070;
  --headerheight: 80px;
  --headerbr: 20px;
  --contentBox: 100vh - var(--headerheight) - 24px - 24px - 24px
}

/* webkit browsers */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #d6d6d6;
  width: 8px;

}

::-webkit-scrollbar-thumb {
  background: #555555;
  border-radius: 5px
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.fCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* height: calc(100vh - var(--headerheight) - 72px); */
}