.fltTableOuter {
  height: calc(var(--contentBox) - 220px);
}

.days {
  background-color: var(--primaryblue);
  color: white;
  padding: 4px;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  font-size: 10px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}