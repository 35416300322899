.headerIcon img {
  width: 14px;
}

.attImgContainer {
  width: 175px;
}

.attachmentPopover {
  /* margin-top: 20px; */
  margin-left: -10px;
}

.reportTable {
  max-height: calc(100vh - 430px);
  overflow: auto;
}

/* .ipLabel :global .MuiInputLabel-root {
  overflow: visible;
  text-overflow: unset;
  margin: auto 10px auto 0;
}

@media screen and (max-width: 900px) {
  .ipLabel :global .MuiInputLabel-root {
    min-width: 90px;
    white-space: normal;
  }
} */